<template>
    <div class="ui-page">
        <div class="nav-bar--placeholder">
            <van-nav-bar
                title="房间列表"
                fixed
                :left-arrow="true"
                @click-left="onClickLeft"
            />
        </div>

        <div>
            <div class="ui-search">
                <div class="ui-search-bar">
                    <div>房号搜索：</div>
                    <div class="flex-item">
                        <input class="ui-search-input" v-model="dataForm.roomNo" placeholder="请输入房号">
                    </div>
                </div>
            </div>
            <div v-show="loading" class="empty-data">
                <van-loading size="24px">加载中...</van-loading>
            </div>
            <div v-if="!loading && !filterDataList.length" class="empty-data">暂无数据</div>
            <div class="flex-row flex-wrap room-list">
                <div class="room-list__item" v-for="item of filterDataList" :key="item.hotelRoomId">
                    <div class="room-list__card" @click="linkRoomControl(item)"><span>{{item.roomNo}}</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RoomList",
    data() {
        return {
            refreshing: false,
            loading: false,
            finished: false,
            dataList: [],
            page: 1,
            limit: 48,
            dataForm: {
                roomNo: ''
            }
        }
    },
    computed: {
        hotelId() {
            return this.$route.query.hotelId
        },
        filterDataList() {
            return this.dataList.filter(item => {
                if(!this.dataForm.roomNo) {
                    return true
                }
                return item.roomNo.indexOf(this.dataForm.roomNo) > -1
            })
        }
    },
    methods: {
        onClickLeft() {
            this.$router.back()
        },
        getList() {
            this.loading = true
            this.$axios.get('/api/weChatMini/v1/hotel/branch/roomList', {
                params: {
                    page: this.page,
                    limit: this.limit,
                    branchId: this.hotelId
                }
            }).then(res => {
                this.refreshing = false
                this.loading = false
                this.finished = true
                this.dataList = res.list
            }).catch(err => {
                this.refreshing = false
                this.loading = false
                this.finished = true
                this.$dialog.alert({
                    message: err.message
                })
            })
        },
        onRefresh() {
            this.page = 1
            this.refreshing = true
            this.getList()
        },
        linkRoomControl(data) {
            this.$router.push({
                path: '/room-control',
                query: {
                    hotelId: data.hotelBranchId,
                    roomId: data.hotelRoomId
                }
            })
        }
    },
    mounted() {
        if(this.$store.state.userMode !== 2) {
            this.$router.replace('/my-rooms')
        }
        this.getList()
    }
}
</script>

<style scoped>

</style>